@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@600&display=swap');

h1 {
  font-weight: 400 !important;
}

h2 {
  font-weight: 400 !important;
}

h3 {
  font-weight: 400 !important;
}

.ui.modal>.header:not(.ui) {
  font-weight: 400 !important;
}